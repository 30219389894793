import { Form } from '@remix-run/react'

import eoLogo from '~/assets/eo-logo.png'
import Footer from '~/components/footer'

export default function Login() {
    return (
        <div className="flex min-h-screen flex-col justify-between">
            <main className="flex w-full justify-center">
                <div className="mt-8 flex flex-col">
                    <div className="flex justify-center">
                        <img
                            alt="Earth Optics Logo with plant growing"
                            className="w-1/3"
                            src={eoLogo}
                        />
                    </div>
                    <div className="mt-24 flex flex-row justify-center">
                        <Form action="/auth/auth0" method="post">
                            <button className="btn-default" type="submit">
                                Login
                            </button>
                        </Form>
                    </div>
                </div>
            </main>
            <div className="flex w-full justify-center">
                <Footer />
            </div>
        </div>
    )
}
